<template>
  <!-- 网页标签标题 -->
  <div v-wechat-title="$route.meta.title"></div>
  <!-- 页面顶部 -->
  <div class="header">
    <div>
      <a-input
        v-model:value="selectTagFrom.tag_name"
        placeholder="请输入标签名称"
        class="selectInput"
        :allowClear="true"
        @change="$refs.table.getTableData()"
      />
      <a-button
        class="selectButtom"
        @click="$refs.table.getTableData()"
      >搜 索</a-button>
    </div>
    <a-button
      type="primary"
      @click="addData()"
    >新增标签</a-button>
  </div>
  <Table
    :columns="columns"
    :getData="selectTag"
    :subHeight=215
    ref="table"
  >
    <!-- column表头标题  text展示文字  record后端返回数据  -->
    <template v-slot="{ column, record }">
      <!-- 操作列 -->
      <template v-if="column.dataIndex == 'action'">
        <template v-if="editTagData[record.id] || record.id == null">
          <a-button
            type="link"
            size="small"
            @click="saveTag(record.id ? record.id : '')"
          >
            保存
          </a-button>
          <a-button
            type="link"
            size="small"
            @click="cancel(record.id ? record.id : '')"
          >
            取消
          </a-button>
        </template>
        <template v-else>
          <a-button
            type="link"
            size="small"
            @click="editBtn(record.id)"
          >编辑</a-button>
          <a-popconfirm
            title="确认删除吗？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="deleteTag(record.id)"
          >
            <a-button
              type="link"
              size="small"
            >删除</a-button>
          </a-popconfirm>
        </template>
      </template>
      <!-- 其余列 -->
      <template v-else>
        <template v-if="column.isEdit">
          <a-input
            v-if="editTagData[record.id]"
            v-model:value="record[column.dataIndex]"
            style="margin: -5px 0"
          />
          <a-input
            v-else-if="record.id == null"
            style="margin: -5px 0"
            v-model:value="insertTagData[column.dataIndex]"
          />
        </template>
      </template>
    </template>
  </Table>
</template>

<script>
import { ref, reactive } from '@vue/reactivity'
import Table from '../components/Table.vue'
import { message } from 'ant-design-vue'
import { SELECTTAG, DELETETAG, UPDATETAG, INSERTTAG } from '../api/api'
import { onMounted } from '@vue/runtime-core'
export default {
  components: {
    Table
  },
  setup() {
    const table = ref(null)
    onMounted(() => {
      table.value.getTableData()
    })
    // 表格字段
    const columns = ref([
      {
        title: 'ID',
        dataIndex: 'id',
        isEdit: false,
        key: 'id',
        width: 50,
        align: 'center'
      },
      {
        title: '标签名称',
        dataIndex: 'tag_name',
        isEdit: true,
        key: 'tag_name',
        width: 300
      },
      {
        title: '标签描述',
        dataIndex: 'tag_describe',
        isEdit: true,
        key: 'tag_describe'
      },
      {
        title: '更新时间',
        dataIndex: 'modified_time',
        isEdit: false,
        key: 'modified_time',
        width: 180,
        align: 'center'
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        isEdit: false,
        key: 'create_time',
        width: 180,
        align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'action',
        isEdit: false,
        key: 'action',
        width: 200,
        align: 'center'
      }
    ])
    // 查询数据表单
    const selectTagFrom = reactive({
      tag_name: ''
    })
    // 查询数据方法
    const selectTag = (pageData) => {
      return new Promise((resolve, reject) => {
        SELECTTAG(Object.assign(pageData, selectTagFrom))
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    // 删除数据方法
    const deleteTag = (id) => {
      DELETETAG({ id }).then((response) => {
        if (response.code === 0) {
          table.value.getTableData()
        }
      })
    }
    const editTagData = reactive({}) // 编辑数据
    // 点击编辑按钮
    const editBtn = (id) => {
      editTagData[id] = table.value.dataSource.filter((item) => item.id === id)
    }
    // 保存数据方法
    const saveTag = (id) => {
      if (id) {
        // 更新数据
        UPDATETAG(
          table.value.dataSource.filter((item) => item.id === id)[0]
        ).then((response) => {
          if (response.code === 0) {
            // 将该id从编辑对象中清除
            delete editTagData[id]
            // 重新获取数据
            table.value.getTableData()
          }
        })
      } else {
        // 新增数据
        INSERTTAG(insertTagData).then((response) => {
          if (response.code === 0) {
            // 重新获取数据
            table.value.getTableData()
            // 清空新增表单
            insertTagData.tag_name = ''
            insertTagData.tag_describe = ''
          }
        })
      }
    }
    // 点击取消按钮
    const cancel = (id) => {
      if (id) {
        // 将该id从编辑对象中清除
        delete editTagData[id]
        table.value.getTableData()
      } else {
        // 清除列表新增对象
        table.value.dataSource.shift()
      }
    }
    // 新增空白数据对象
    const insertTagData = reactive({
      tag_name: '',
      tag_describe: ''
    })
    // 点击新增按钮
    const addData = () => {
      // 判断列表数据存在待保存的新增数据，则不允许再添加
      if (table.value.dataSource.length !== 0) {
        if (table.value.dataSource[0].id == null) {
          message.warning('存在待保存的数据！')
        } else {
          table.value.dataSource.unshift(insertTagData)
        }
      } else {
        table.value.dataSource.unshift(insertTagData)
      }
    }
    return {
      table,
      selectTagFrom,
      columns,
      selectTag,
      deleteTag,
      editTagData,
      editBtn,
      saveTag,
      cancel,
      insertTagData,
      addData
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.selectInput {
  margin-bottom: 8px;
  width: 250px;
}

.selectButtom {
  margin-left: 8px;
}
</style>
